<template>
    <v-app id="inspire">
        <navigation v-if="showNavigation" />
        <home-toolbar v-if="showNoAuthComponents" />

        <v-main>
            <v-container>
                <flash-message />
                <router-view :key="$route.path" />
            </v-container>
        </v-main>

        <site-footer v-if="showNoAuthComponents" />
    </v-app>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import navigation from './Navigation.vue';
    import FlashMessage from './FlashMessage.vue';
    import HomeToolbar from './HomeToolbar.vue';
    import SiteFooter from './SiteFooter.vue';
    import loadGroove from '../helpers/groove';

    export default {
        components: {
            navigation,
            FlashMessage,
            HomeToolbar,
            SiteFooter,
        },

        data() {
            return {
                showNavigation: false,
                showInContainer: true,
            };
        },

        computed: {
            showNoAuthComponents() {
                const { auth, toolbar } = this.route.meta;

                if (toolbar !== undefined && toolbar === false) {
                    return false;
                }

                if (auth === undefined) {
                    return false;
                }

                if (auth === false) {
                    return true;
                }

                return false;
            },

            route() {
                return this.$route;
            },

            ...mapGetters({
                authCheck: 'app/auth',
            }),
        },

        watch: {
            '$route.name': {
                handler() {
                    this.updateNavigation();
                    this.loadExternalScriptsForPagesWithoutAuth();
                },
            },

            authCheck: {
                handler(auth) {
                    if (auth !== true) {
                        return;
                    }

                    this.updateNavigation();
                },
            },
        },

        created() {
            this.loadAssistance();
        },

        methods: {
            updateNavigation() {
                const { meta } = this.route;

                if (meta.navigation === false) {
                    this.showNavigation = false;
                    return;
                }

                // will match undefined and false and hide navigation
                if (! meta.auth) {
                    this.showNavigation = false;
                } else {
                    this.showNavigation = true;
                }
            },

            loadExternalScriptsForPagesWithoutAuth() {
                if (this.route.meta.auth === true) {
                    return;
                }

                if (this.route.meta.preventGroove !== true) {
                    loadGroove();
                }
            },

            ...mapActions({
                loadAssistance: 'assistance/loadAssistance',
            }),
        },
    };
</script>

<style>
    .container {
        max-width: 1785px !important;
    }
</style>
