export default [
    {
        path: '/embed/analysis-tree',
        name: 'analysis-tree-embed',
        component: () => import('../pages/AnalysisTree.vue'),
        meta: {
            auth: false,
            navigation: false,
            toolbar: false,
            preventGroove: true,
        },
    },
];
